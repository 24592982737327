import React, { useEffect, useState } from "react"
import { Elements, LinkAuthenticationElement } from "@stripe/react-stripe-js"
import CheckoutForm from "./CheckoutForm"
import { loadStripe } from "@stripe/stripe-js"
import https from "../../../../services/https"
import { API_URL } from "../../../../primeagile.config"
import { AddressElement } from "@stripe/react-stripe-js"
import styled from "styled-components"
import { useSelector } from "react-redux"
import CartItem from "../Cart/CartItem"
const stripePromise = loadStripe(process.env.GATSBY_STRIPE)

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 20px;
  padding: 20px;

  .contactItem {
    grid-column: 1/6;
  }
  .paymentMethod {
    grid-column: 6/13;
  }

  .cartitem {
    grid-column: 6/13;

    .container {
      box-shadow: 0px 3px 10px rgba(18, 42, 66, 0.08);
      padding: 10px;

      .carttotal {
        display: flex;
        justify-content: end;
        gap: 15px;
      }
    }
    .cartcontent {
      width: 100%;
      display: flex;
      gap: 12px;
      padding: 5px;
    }

    h4,
    h5 {
      margin: 0 !important;
    }
  }
  .address {
    grid-row: 3;
    grid-column: 1/6;
  }

  button {
    font-size: clamp(1rem, 2.5vh, 1.5rem);
    font-weight: 500;
    background-color: #f360a6;
    color: white;
    padding: 15px 20px;
    outline: none;
    border-radius: 4px;
    border: none;
    margin-top: 10px;
    cursor: pointer;
  }

  @media screen and (max-width: 990px) {
    display: flex;
    flex-direction: column;
    .address {
      /* margin-top: -20px; */
    }

    .paymentMethod {
      order: 2;
      /* margin-top: -50px; */
    }
  }
`

const loader = "auto"
const appearance = {
  theme: "stripe",
  variables: {
    fontWeightNormal: "500",
    borderRadius: "2px",
    colorPrimary: "#f360a6",
    tabIconSelectedColor: "#fff",
    gridRowSpacing: "16px",
  },
  rules: {
    ".Tab, .Input, .Block, .CheckboxInput, .CodeInput": {
      boxShadow: "0px 3px 10px rgba(18, 42, 66, 0.08)",
    },
    ".Block": {
      borderColor: "transparent",
    },
    ".BlockDivider": {
      backgroundColor: "#ebebeb",
    },
    ".Tab, .Tab:hover, .Tab:focus": {
      border: "0",
    },
    ".Tab--selected, .Tab--selected:hover": {
      backgroundColor: "#f360a6",
      color: "#fff",
    },
  },
}

function Payment({ total }) {
  const [clientSecret, setClientSecret] = useState("")
  const { userCart } = useSelector(state => state.page)
  const [formdata, setFormdata] = useState({
    email: "",
    name: "",
    phone: "",
    country: "",
    city: "",
    addressone: "",
    addresstwo: "",
    zip: "",
    state: "",
  })

  useEffect(() => {
    // Create PaymentIntent as soon as the page loads

    if (total) {
      const price = total?.toString().replace(/[^a-zA-Z0-9]/g, "")
      const data = {
        amount: price.length > 3 ? price : price + "00",
        applicationFeeAmount: 0,
        currency: "usd",
        tenant: process.env.GATSBY_BUILDSITE,
      }

      https(
        `${API_URL}/open/commerce/stripe/payment_intent`,
        "post",
        data,
        undefined,
        res => {
          setClientSecret(res.data.clientSecret)
        },
        err => {
          console.log(err)
        }
      )
    }
  }, [total])



  return (
    <Container>
      {clientSecret && stripePromise && (
        <Elements
          stripe={stripePromise}
          options={{ clientSecret, appearance, loader }}
        >
          <h1>Payment</h1>
          <div className="contactItem">
            <h3>Contact info</h3>
            <LinkAuthenticationElement
              onChange={event => {
                setFormdata(p => ({
                  ...p,
                  email: event.value?.email,
                }))
              }}
            />
          </div>
          <div className="cartitem">
            <h3>Cart</h3>
            <div className="container">
              {userCart.length > 0 &&
                userCart.map(item => {
                  if (item === null) return
                  let subprice = parseFloat(item?.price)
                    ? parseFloat(item?.price) * item?.quantity
                    : 0
                  return (
                    <div className="cartcontent" key={item.code}>
                      <div>
                        <img src={item.image} width={"50px"} />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "5px",
                        }}
                      >
                        <h4>{item.title}</h4>
                        <div>
                          <span>Amount</span>
                          <h5>{item.quantity + " " + "x" + item.price}</h5>
                          <h5>{subprice}</h5>
                        </div>
                      </div>
                    </div>
                  )
                })}
              <div className="carttotal">
                <h4>Total:</h4>
                <h4>${total}</h4>
              </div>
            </div>
          </div>

          <div className="paymentMethod">
            <h3>Payment Method</h3>
            <CheckoutForm userCart={userCart} formdata={formdata} />
          </div>

          <div className="address">
            <h3>Shipping</h3>
            <AddressElement
              onChange={event => {
                setFormdata(p => ({
                  ...p,
                  name: event.value?.name,
                  phone: event.value.phone,
                  country: event.value.address.country,
                  city: event.value.address.city,
                  addressone: event.value.address.line1,
                  addresstwo: event.value.address.line2,
                  zip: event.value.address.postal_code,
                  state: event.value.address.state,
                  firstname: event.value.firstName
                }))
              }}
              options={{
                mode: "shipping",
                blockPoBox: true,
                fields: {
                  phone: "always",
                  firstName: 'always',
                  lastName: 'always'
                },
                validation: {
                  phone: {
                    required: "never",
                  },
                },
              }}
            />
          </div>
        </Elements>
      )}
    </Container>
  )
}

export default Payment
