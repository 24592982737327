import React, { Suspense } from "react"


export const Notify = ({ text, type }) => {
  import("react-toastify").then(module => {
    import("react-toastify/dist/ReactToastify.css")
    return module.toast(text, {
      position: "top-left",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      type: type,
      zIndex: 999999999,
    })
  })

  const ToastContainer = React.lazy(() =>
    import("react-toastify").then(module => {
      return { default: module.ToastContainer }
    })
  )

  return (
    <Suspense fallback={<div />}>
      <ToastContainer  />
    </Suspense>
  )
}
